import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { usePhoneNumber } from '@/hooks/usePhoneNumber';
import { componentColors } from '@/layout/components/DealerWebHeader/constants';
import { ButtonLink } from '@/components/Button';
import Link from '@/components/Link';
import { media } from '@/utils/mixin';
import useSettingItem from '@/hooks/useSettingItem';
import { getPhoneLink } from '@/utils/common';
import { googleEvent } from '@/utils/events';

import { getButtonLinkVariant } from './helpers';

function Personal() {
  const phone = usePhoneNumber();
  const isHideButtonCall = useSettingItem('HEADER_DEALER_WEB_HIDE_BUTTON_CALL');
  const isHideButtonLogin = useSettingItem(
    'HEADER_DEALER_WEB_HIDE_BUTTON_LOGIN'
  );
  const phoneClickGoogleEvent = useSettingItem('PHONE_CLICK_GOOGLE_EVENT');

  const isMobile = useMedia('(max-width: 1259px)');
  const buttonLinkVariant = getButtonLinkVariant(isMobile);

  const onPhoneClick = () => {
    if (phoneClickGoogleEvent) {
      googleEvent(phoneClickGoogleEvent);
    }
  };

  const onPhoneSubmit = () => {
    window.location.hash = 'cbh-open';
  };

  return (
    <Component>
      <InfoWrapper>
        <InfoNumber to={getPhoneLink(phone)} onClick={onPhoneClick}>
          {phone}
        </InfoNumber>
        {!isHideButtonCall && (
          <InfoButton onClick={onPhoneSubmit}>Заказать звонок</InfoButton>
        )}
      </InfoWrapper>

      {!isHideButtonLogin && (
        <StyledButtonLink
          variant={buttonLinkVariant}
          href="https://lkk.atlantm.by/"
          target="_blank"
        >
          Войти
        </StyledButtonLink>
      )}
    </Component>
  );
}

export default Personal;

const Component = styled.div`
  display: flex;
  align-items: center;

  ${media.tablet(css`
    flex-direction: column;
    align-items: flex-start;
  `)}
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${media.tablet(css`
    align-items: flex-start;
  `)}
`;

const InfoNumber = styled(Link)`
  font-size: 16px;
  line-height: 130%;
  white-space: nowrap;
  color: ${componentColors.headerPhone};

  &:hover {
    color: ${componentColors.headerPhoneHover};
  }
`;

const InfoButton = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  white-space: nowrap;
  margin-top: 2px;
  color: ${componentColors.headerPhoneButton};
  transition: 0.3s;

  &:hover {
    color: ${componentColors.headerPhoneButtonHover};
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  max-width: 110px;
  margin-left: 40px;

  ${media.tablet(css`
    max-width: none;
    margin: 40px 0 0;
  `)}
`;
